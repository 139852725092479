import React from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
// Material UI
import { Link as ExternalLink, Link, Theme, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
// import { Link } from 'react-router-dom';

// utils
// actions
// Components
import ForumIcon from '@material-ui/icons/Forum';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PublicIcon from '@material-ui/icons/Public';
import TwitterIcon from '@material-ui/icons/Twitter';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import { APP_BASE_URL, LEGAL_URL, SERVER_HOST } from '../../utils/configs';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import SwitchThemeButton from '../SwitchThemeButton/SwitchThemeButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: 'hidden',
    backgroundColor: '#303030',
    paddingTop: ({ enableSocialFooter }: { enableSocialFooter: boolean }) =>
      enableSocialFooter ? 16 : 0,
    boxShadow: ({ enableSocialFooter }) =>
      enableSocialFooter
        ? 'none'
        : '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
  },
  menuContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 1000,
    margin: '0 auto',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  select: {
    // justifyContent: 'center',
    display: 'flex',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  toolbar: {
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  title: {
    margin: '0 20px',
    color: 'white',
  },
  color: {
    color: 'white',
    textDecoration: 'none',
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.complex,
    }),
    '&:hover': {
      opacity: 0.8,
    },
  },
  companyInfoRoot: {
    borderTop: '1px solid white',
    backgroundColor: '#424242',
  },
  companyInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    padding: 10,
  },
  companyInfoName: {
    margin: 0,
    paddingRight: 10,
    alignItems: 'center',
    justifyItems: 'center',
    display: 'flex',
    fontSize: 12,
    flex: 'inherit',
    color: 'white',
  },
  icon: {
    fontSize: 12,
    marginBottom: 1,
    color: 'white',
  },
  tooltip: {
    background: '#000',
    marginBottom: 8,
    maxWidth: 288,
  },
  arrow: {
    color: ' #141414',
  },
  socialContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: 24,
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  socialItem: {
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
  },
  socialName: {
    textTransform: 'uppercase',
    margin: 0,
    marginLeft: 8,
  },
}));

const TOOLTIP_TEXT = `
  Complex Contents Ltd  -  206316562, 65 Shipchenski prohod Str #218, Sofia, Bulgaria
  Secondary payments: Phoenix Media Ltd  -  200333035, 1 6th September, Sofia, Bulgaria
`;

const Footer = ({ enableSocialFooter = false }: { enableSocialFooter?: boolean }) => {
  const classes = useStyles({ enableSocialFooter });
  const { t } = useTranslation();
  const [cookies] = useCookies();

  const theme = cookies.theme || 'white';

  const generateLegalUrl = () => {
    if (![LEGAL_URL, SERVER_HOST].includes(window.location.origin)) {
      return window.location.origin;
    }
    return LEGAL_URL || SERVER_HOST;
  };

  const downloadLink = `${APP_BASE_URL || SERVER_HOST}/app-download`

  return (
    <footer className={classes.root}>
      <div className={classes.menuContainer}>
        <Toolbar className={classes.toolbar}>
          {enableSocialFooter ? (
            <div className={classes.socialContainer}>
              <Link href="https://www.sankakucomplex.com/" className={classes.socialItem}>
                <ViewListOutlinedIcon />
                <p className={classes.socialName}>{t('common-title__news')}</p>
              </Link>
              <Link href="https://twitter.com/sankakucomplex" className={classes.socialItem}>
                <TwitterIcon />
                <p className={classes.socialName}>{t('common-title__twitter')}</p>
              </Link>
              <Link href="https://forum.sankakucomplex.com/" className={classes.socialItem}>
                <ForumIcon />
                <p className={classes.socialName}>{t('common-title__forum')}</p>
              </Link>
              <Link href="https://chan.sankakucomplex.com/" className={classes.socialItem}>
                <PublicIcon />
                <p className={classes.socialName}>{t('common-title__channel')}</p>
              </Link>
            </div>
          ) : (
            <>
              <ExternalLink
                className={classes.title}
                variant="subtitle1"
                color="inherit"
                gutterBottom
                href={`${downloadLink}?theme=${theme}`}
              >
                {t('common-title__app-download')}
              </ExternalLink>
              <ExternalLink
                className={classes.title}
                variant="subtitle1"
                color="inherit"
                gutterBottom
                href={`${generateLegalUrl()}/terms-of-service?theme=${theme}`}
              >
                {t('common-title__terms-of-service')}
              </ExternalLink>
              <ExternalLink
                className={classes.title}
                variant="subtitle1"
                color="inherit"
                gutterBottom
                href={`${generateLegalUrl()}/privacy-policy?theme=${theme}`}
              >
                {t('common-title__policy')}
              </ExternalLink>
              <Typography
                variant="subtitle1"
                color="inherit"
                component="p"
                className={classes.title}
              >
                <a href="mailto:contact@sankakucomplex.com" className={classes.color}>
                  {t('footer__title_contact')}
                </a>
              </Typography>
            </>
          )}
        </Toolbar>
        <div className={classes.select}>
          <LanguageSelect />
          <SwitchThemeButton />
        </div>
      </div>
      <section className={classes.companyInfoRoot}>
        <div className={classes.companyInfoContainer}>
          <Typography component="p" className={classes.companyInfoName}>
            © Complex Contents Ltd
          </Typography>

          <Tooltip
            arrow
            interactive
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            title={TOOLTIP_TEXT}
            enterTouchDelay={0}
            leaveTouchDelay={2000}
            placement="top"
          >
            <InfoOutlinedIcon className={classes.icon} />
          </Tooltip>
        </div>
      </section>
    </footer>
  );
};

Footer.whyDidYouRender = true;

export default Footer;
