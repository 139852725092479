import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Loader from '../Loader';

const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    height: '100vh',
    width: '100%',
    backgroundColor: theme.palette.colors?.white,
  },
}));

const BigLoaderWrap = ({ haveLoading = true }) => {
  const classes = useStyles();
  return (
    <div className={classes.loader}>
      <Loader haveLoading={haveLoading} size={80} />
    </div>
  );
};

BigLoaderWrap.whyDidYouRender = true;

export default BigLoaderWrap;
