import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import Loader from './Loader';
import LoadingError from './LoadingError';

// Material UI
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.body,
    height: `calc(100vh - ${theme.spacing(8)}px)`,
    [theme.breakpoints.down('xs')]: {
      height: `calc(100vh - ${theme.spacing(7)}px)`,
    },
  },
}));

const Loading = ({ pastDelay, error }: { pastDelay: boolean; error?: object }) => {
  const classes = useStyles();

  if (error) {
    return <LoadingError />;
  }
  if (pastDelay) {
    return <Loader size={80} classNames={classes.root} />;
  }
  return null;
};

Loading.whyDidYouRender = true;

export default Loading;
