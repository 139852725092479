import ACTIONS from './constants';

export const openOtpDialog = (data) => ({
  type: ACTIONS.OPEN_OTP_DIALOG,
  data,
});

export const closeOtpDialog = (data) => ({
  type: ACTIONS.CLOSE_OTP_DIALOG,
  data,
});

export const setOtpDialogState = (data) => ({
  type: ACTIONS.SET_OTP_DIALOG_STATE,
  data,
});
