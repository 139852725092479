import React from 'react';
// Material UI
import { Button, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
// import Trans from '../../Trans';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: `calc(100vh - ${theme.spacing(8)}px)`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `0 ${theme.spacing(3)}px`,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      height: `calc(100vh - ${theme.spacing(7)}px)`,
    },
  },
  message: {
    fontSize: 22,
    marginTop: 0,
  },
}));

const LoadingError = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <Trans component="h2" id="error_cannot_load" className={classes.message} /> */}
      <div>
        <Button onClick={() => window.location.reload()} variant="text">
          Retry
        </Button>
      </div>
    </div>
  );
};

LoadingError.whyDidYouRender = true;

export default LoadingError;
