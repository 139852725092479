import { Hidden, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useCookies } from 'react-cookie';

import { SERVER_HOST } from '../../utils/configs';
import SankakuLogo from '../Logo/logo.svg';
import sankakuLogoSmall from '../Logo/triangle-grey.svg';

// Material UI
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    maxHeight: theme.spacing(7),
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: `-${theme.spacing(3)}px`,
  },
  img: {
    height: 30,
  },
}));

const Logo = () => {
  const classes = useStyles();
  const [cookies] = useCookies(['theme']);
  const theme = cookies.theme || 'white';

  return (
    <a href={`${SERVER_HOST}?theme=${theme}`} className={classes.root}>
      <Hidden smDown implementation="css">
        <img
          className={classes.img}
          width={175.55}
          height={30}
          loading="lazy"
          src={SankakuLogo}
          alt="Sankaku Complex"
        />
      </Hidden>
      <Hidden only={['md', 'lg', 'xl']} implementation="css">
        <img
          className={classes.img}
          src={sankakuLogoSmall}
          alt="Sankaku Complex"
          loading="lazy"
        />
      </Hidden>
    </a>
  );
};

Logo.whyDidYouRender = true;

export default Logo;
