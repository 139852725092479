import { useEffect } from 'react';
import qs from 'querystring';
import { useLocation } from 'react-router';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import base64url from 'base64url';
import queryStr from 'query-string';
import {
  SANKAKU_APP,
  SERVER_HOST,
  SANKAKU_APP_CLIENT_ID,
  SANKAKU_PAYMENT_CLIENT_ID,
  PAYMENTS_URL,
} from '../utils/configs';
import { DEFAULT_LANG } from '../utils/i18n';

export const authRoutes = ['login', 'registration'];

const OIDC_GRANT_CACHED_EXPIRED_TIME = 10; // 10 minutes

const useVerifiedQuery = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const [cookies] = useCookies(['theme', '_interacted']);
  const { query, pathname } = location;

  const queryParams = queryStr.parse(base64url.decode(query?.entry_query || ''));

  useEffect(() => {
    if (!authRoutes.includes(pathname.slice(1))) {
      return;
    }
    const params = {
      response_type: 'code',
      scope: 'openid',
      client_id: `${SANKAKU_APP_CLIENT_ID}`,
      redirect_uri: `${SANKAKU_APP}/sso/callback`,
      state: `return_uri=${SANKAKU_APP}/auth`,
      theme: cookies.theme || 'white',
      route: pathname.slice(1) || 'login',
      lang: i18n.language || DEFAULT_LANG,
      to_payments: JSON.parse(query.to_payments || 'false'),
      ...queryParams,
    };

    if (query.state && JSON.parse(query.to_payments || 'false')) {
      params.client_id = SANKAKU_PAYMENT_CLIENT_ID as string;
      params.redirect_uri = `${PAYMENTS_URL}/sso/callback`;
      params.state = query.state;
      params.to_payments = true;
    }

    const queryString = qs.stringify(params);
    if (!query.auth_type || query.auth_type !== 'oidc' || !cookies._interacted) {
      window.location.href = `${SERVER_HOST}/oidc/auth?${queryString}`;
    } else {
      let minCount = 0;
      setInterval(() => {
        if (minCount <= OIDC_GRANT_CACHED_EXPIRED_TIME * 60) {
          minCount += 1;
        } else {
          window.location.href = `${SERVER_HOST}/oidc/auth?${queryString}`;
        }
      }, 1000);
    }
  }, [pathname]);

  return true;
};

export default useVerifiedQuery;
