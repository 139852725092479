import React from 'react';
// Material UI
import { makeStyles } from '@material-ui/core/styles';

import Loader from '../Loader';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: theme.palette.background.body,
    width: '100%',
    height: '100%',
    zIndex: 99,
  },
}));

const LoaderBackdrop = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Loader size={80} classNames={classes.root} />
    </div>
  );
};

export default LoaderBackdrop;
